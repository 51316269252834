import { motion } from "framer-motion";
import styled from "styled-components";

export const Close = styled.img`
  cursor: pointer;
  position: absolute;
  right: 6.4rem;
  top: 5.2rem;
`;
export const Modal = styled.div``;
export const Overlay = styled(motion.div)`
  background: ${({ theme }) => theme.color.black.a60};
  backdrop-filter: blur(1rem);
  bottom: 0;
  display: grid;
  left: 0;
  place-items: center;
  position: absolute;
  right: 0;
  top: 0;
`;
