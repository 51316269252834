import styled, { css } from "styled-components";

export const AdditionalText = styled.span(({ theme }) => {
  return css`
    color: ${theme.color.white.a70};
    font-size: ${theme.font.size.xs};
    padding-left: 1.2rem;
    text-align: left;
    text-decoration: underline;
  `;
});
export const Input = styled.input(({ theme }) => {
  return css`
    background: transparent;
    border: 0.1rem solid ${theme.color.white.a20};
    border-radius: 99rem;
    color: ${theme.color.white.a60};
    font-size: ${theme.font.size.s};
    margin: 0.4rem 0;
    transition: all ${theme.transition.time.default} ease-in;
    padding: 1rem 1.2rem;
    &:focus {
      border: 0.1rem solid ${theme.color.white.a60};
      outline: none;
    }
  `;
});
export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Label = styled.label(() => {
  return css`
    padding-left: 1.2rem;
    text-align: left;
  `;
});
