import { Title } from "components/Title";

export const Settings = () => {
  return (
    <>
      <Title title="Ustawienia" />
      Settings
    </>
  );
};
