import styled from "styled-components";
import { BUTTON_VARIANTS, StyledButtonProps } from "./Button.types";

export const Button = styled.button<StyledButtonProps>`
  background: ${({ theme, variant }) =>
    variant === BUTTON_VARIANTS.PRIMARY ? theme.color.blue.a30 : "transparent"};
  border: 0.1rem solid ${({ theme }) => theme.color.white.a20};
  border-radius: 1000rem;
  color: ${({ theme }) => theme.color.white.a90};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  padding: 1rem 6rem;
  text-transform: uppercase;
  transition: all ${({ theme }) => theme.transition.time.default} ease-in;
  &:hover {
    background: ${({ theme, variant }) =>
      variant === BUTTON_VARIANTS.PRIMARY
        ? theme.color.blue.a60
        : theme.color.white.a10};
  }
`;
