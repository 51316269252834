import styled from "styled-components";

export const DocumentsHeader = styled.div`
  align-items: center;
  display: flex;
  font-size: 2.3rem;
  justify-content: space-between;
  padding: 1.8rem 2.4rem 1.8rem 6.4rem;
  span {
    color: ${({ theme }) => theme.color.white.a80};
    font-size: ${({ theme }) => theme.font.size.m};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    margin-left: 1rem;
  }
`;
export const HorizontalSpacer = styled.div`
  background: linear-gradient(
    270.01deg,
    rgba(255, 255, 255, 0.2) -0.65%,
    rgba(0, 14, 35, 0.2) 105.51%
  );

  height: 0.1rem;
`;
export const SectionTitle = styled.h1`
  margin: 0;
`;
export const Spacer = styled.div`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) -0.65%,
    rgba(0, 14, 35, 0.2) 105.51%
  );
  height: 100%;
  width: 0.1rem;
`;
export const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.size.xxl};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  margin: 1.6rem ${({ theme }) => theme.padding.default};
`;
export const Wrapper = styled.main`
  align-items: flex-start;
  display: grid;
  grid-template-columns: 1fr 0.1rem 48.5rem;
  margin: 00;
`;
