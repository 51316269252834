import { DocumentList } from "components/DocumentList";
import { MeestCard } from "components/MeestCard";
import { QRCode } from "components/QRCode";
import { Summary } from "components/Summary";
import { Title } from "components/Title";
import { motion } from "framer-motion";
import * as Styled from "./Dashboard.styles";

export const Dashboard = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ bounce: 0 }}
    >
      <Title title="Pulpit" />
      <Styled.Wrapper>
        <div>
          <Summary />
          <Styled.HorizontalSpacer />
          <Styled.Title>Ostatnie dokumenty</Styled.Title>
          <Styled.HorizontalSpacer />
          <DocumentList />
        </div>
        <Styled.Spacer />
        <div>
          <MeestCard />
          <QRCode />
        </div>
      </Styled.Wrapper>
    </motion.div>
  );
};
