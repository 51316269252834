import * as Styled from "./QRCode.styles";
import qrCode from "assets/img/qrCode.big.png";

export const QRCode = () => {
  return (
    <Styled.Wrapper>
      <Styled.Title>Zeskanuj swoją kartę</Styled.Title>
      <img src={qrCode} alt="QRCode" />
    </Styled.Wrapper>
  );
};
