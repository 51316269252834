import styled from "styled-components";

export const Title = styled.h3`
  font-size: ${({ theme }) => theme.font.size.xl};
  margin: 0;
  margin-bottom: 1.6rem;
  margin-top: -1.2rem;
`;
export const Wrapper = styled.div`
  opacity: 0.8;
  text-align: center;
`;
