import { InputProps } from "./Input.types";
import * as Styled from "./Input.styles";

export const Input = ({ additionalText, labelText, ...props }: InputProps) => {
  return (
    <Styled.InputGroup>
      {labelText && <Styled.Label>{labelText}</Styled.Label>}
      <Styled.Input {...props} />
      {additionalText && (
        <Styled.AdditionalText>{additionalText}</Styled.AdditionalText>
      )}
    </Styled.InputGroup>
  );
};
