import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

export const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  justify-content: center;
  margin-top: 3.6rem;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;
export const Link = styled(RouterLink)`
  text-decoration: underline;
`;
export const LogoContainer = styled.div`
  left: 6.4rem;
  position: absolute;
  top: 3rem;
  z-index: 2;
  @media (max-width: 40rem) {
    left: 1.6rem;
  }
`;
export const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.size.xxxxxl};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
`;
export const Wrapper = styled.div`
  align-items: stretch;
  background-color: #031127;
  background: radial-gradient(
      308.54% 71.68% at 4% 0.92%,
      rgba(131, 173, 255, 0.15) 0%,
      rgba(19, 25, 58, 0) 100%
    ),
    #031127;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  height: 100vh;
  justify-content: center;
  margin: 0 auto;
  padding: 8.7rem 11.1rem;
  text-align: center;
  width: 64.4rem;
  max-width: 64.4rem;
  @media (max-width: 40rem) {
    padding: 1.6rem;
    width: 100%;
  } ;
`;
