import { Navigate, useLocation } from "react-router-dom";
import { ROUTE } from "routes/routes.types";
import { ProtectedRouteProps } from "./ProtectedRoute.types";

export const ProtectedRoute = ({
  component: RouteComponent,
}: ProtectedRouteProps) => {
  const location = useLocation();
  const isAuthenticated = localStorage.getItem("logged") === "yaa";
  if (isAuthenticated) return <RouteComponent />;

  return <Navigate to={ROUTE.LOGIN} replace state={{ from: location }} />;
};
