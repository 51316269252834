import { ModalProps } from "./Modal.types";
import * as Styled from "./Modal.styles";
import close from "assets/img/close.png";
import { motion } from "framer-motion";

export const ModalOverlay = ({ children, setShow }: ModalProps) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ bounce: 0 }}
    >
      <Styled.Overlay
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Styled.Close src={close} alt="Close" onClick={() => setShow(false)} />
        {children}
      </Styled.Overlay>
    </motion.div>
  );
};
