import { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { LoginStyle } from "theme/globalStyle";
import { theme } from "theme/mainTheme";

export const LoginTemplate = ({ children }: { children: ReactNode }) => {
  return (
    <ThemeProvider theme={theme}>
      <LoginStyle />
      {children}
    </ThemeProvider>
  );
};
