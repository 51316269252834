import { ReactNode } from "react";

export enum CHIP_TYPE {
  SUCCESS = "green",
  WARNING = "orange",
}

export interface StyledChipProps {
  variant: CHIP_TYPE;
}
export interface ChipProps extends StyledChipProps {
  children: ReactNode;
}
