import styled from "styled-components";

export const Nav = styled.nav`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 8.8rem;
  padding: 0 ${({ theme }) => theme.padding.default};
  position: relative;
  &::after {
    background: linear-gradient(
      270.01deg,
      rgba(255, 255, 255, 0.2) -0.65%,
      rgba(0, 14, 35, 0.2) 105.51%
    );
    bottom: -0.1rem;
    content: "";
    height: 1px;
    left: 0;
    right: 0;
    width: 100%;
    position: absolute;
  }
`;
