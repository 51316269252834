import { ModalProps } from "./Modal.types";
import { createPortal } from "react-dom";
import { ModalOverlay } from "./ModalOverlay";

export const Modal = ({ children, show, setShow }: ModalProps) => {
  return show
    ? createPortal(
        <ModalOverlay setShow={setShow}>{children}</ModalOverlay>,
        document.getElementById("modal")!,
      )
    : null;
};
