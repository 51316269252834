import { Button } from "components/Button";
import { DocumentList } from "components/DocumentList";
import { MeestCard } from "components/MeestCard";
import { QRCode } from "components/QRCode";
import { Summary } from "components/Summary";
import * as Styled from "./Documents.styles";
import { motion } from "framer-motion";
import { useState } from "react";
import { AddNewDocumentModal } from "components/AddNewDocumentModal";
import { Title } from "components/Title";

export const Documents = () => {
  const [modal, setModal] = useState(false);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ bounce: 0 }}
    >
      <Title title="Dokumenty" />
      <Styled.Wrapper>
        <div>
          <Styled.DocumentsHeader>
            <Styled.SectionTitle>
              Dokumenty<span>Ilość: 4</span>
            </Styled.SectionTitle>
            <AddNewDocumentModal show={modal} setShow={setModal} />
            <Button onClick={() => setModal(true)}>Dodaj nowy dokument</Button>
          </Styled.DocumentsHeader>
          <Styled.HorizontalSpacer />
          <Summary />
          <Styled.HorizontalSpacer />
          <DocumentList />
        </div>
        <Styled.Spacer />
        <div>
          <MeestCard />
          <QRCode />
        </div>
      </Styled.Wrapper>
    </motion.div>
  );
};
