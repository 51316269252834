import * as Styled from "./MeestCard.styles";
import qrCode from "assets/img/qrCode.small.png";

export const MeestCard = () => {
  return (
    <Styled.Wrapper>
      <Styled.Title>Twoja karta Meest TAX FREE</Styled.Title>
      <Styled.Content>
        <Styled.QRCodeWrapper>
          <Styled.QRCode src={qrCode} alt="QRCode" />
        </Styled.QRCodeWrapper>
        <Styled.Owner>
          <Styled.Label>Imię</Styled.Label>
          <Styled.Value>Jane</Styled.Value>
          <Styled.Label>Nazwisko</Styled.Label>
          <Styled.Value>Doe</Styled.Value>
          <Styled.Label>Kraj</Styled.Label>
          <Styled.Value>Ukraine</Styled.Value>
        </Styled.Owner>
      </Styled.Content>
      <Styled.CardNumber>
        <Styled.NumberSymbol>№</Styled.NumberSymbol> 2349 8019 8309 8029 3822
      </Styled.CardNumber>
    </Styled.Wrapper>
  );
};
