import { AnimatePresence } from "framer-motion";
import { useLocation, useRoutes } from "react-router-dom";

import { routes as routesObj } from "routes/routes";
import { LoginTemplate } from "templates/LoginTemplate";
import { MainTemplate } from "templates/MainTemplate";

const App = () => {
  const location = useLocation();
  const routes = useRoutes(routesObj, location);
  const { pathname } = location;
  const Template =
    pathname === "/login" || pathname === "/register"
      ? LoginTemplate
      : MainTemplate;
  return (
    <Template>
      <AnimatePresence exitBeforeEnter>{routes}</AnimatePresence>
    </Template>
  );
};

export default App;
