import { ThemeProvider } from "styled-components";
import { theme } from "theme/mainTheme";
import { MainTemplateProps } from "./MainTemplate.types";
import * as Styled from "./MainTemplate.styles";
import { GlobalStyle } from "theme/globalStyle";
import { Nav } from "components/Nav";

export const MainTemplate = ({ children }: MainTemplateProps) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Styled.Wrapper>
        <Nav />
        {children}
      </Styled.Wrapper>
    </ThemeProvider>
  );
};
