import * as Styled from "./DocumentList.styles";
import leclerc from "assets/img/leclerc.png";
import { Chip } from "components/Chip";
import { CHIP_TYPE } from "components/Chip/Chip.types";

const tmp = ["", "", "", "", "", "", "", "", "", ""];

export const DocumentList = () => {
  return (
    <Styled.Wrapper>
      <Styled.Table>
        <Styled.Row>
          <Styled.Cell>
            <img src={leclerc} alt="Logo" />
          </Styled.Cell>
          <Styled.Cell>Polska, E.Leclerc</Styled.Cell>
          <Styled.Cell>Meest Pay</Styled.Cell>
          <Styled.Cell>138,00 PLN</Styled.Cell>
          <Styled.Cell center>
            <Chip variant={CHIP_TYPE.SUCCESS}>Wykonano</Chip>
          </Styled.Cell>
          <Styled.Cell>2022-04-10</Styled.Cell>
        </Styled.Row>

        {tmp.map(() => (
          <>
            <Styled.Row>
              <Styled.Spacer colSpan={6} />
            </Styled.Row>
            <Styled.Row>
              <Styled.Cell>
                <img src={leclerc} alt="Logo" />
              </Styled.Cell>
              <Styled.Cell>Polska, E.Leclerc</Styled.Cell>
              <Styled.Cell>Meest Pay</Styled.Cell>
              <Styled.Cell>138,00 PLN</Styled.Cell>
              <Styled.Cell center>
                <Chip variant={CHIP_TYPE.WARNING}>Oczekiwanie</Chip>
              </Styled.Cell>
              <Styled.Cell>2022-04-10</Styled.Cell>
            </Styled.Row>
          </>
        ))}
      </Styled.Table>
    </Styled.Wrapper>
  );
};
