const unit = "rem";

export const theme = {
  color: {
    black: {
      fill: "#031127",
      a60: "rgba(0, 14, 35, 0.6)",
    },
    blue: {
      fill: "#0057ff",
      a30: "rgba(0, 87, 255, 0.3)",
      a60: "rgba(0, 87, 255, 0.6)",
    },
    green: {
      fill: "#2dcb05",
      a50: "rgba(45, 203, 5, 0.5)",
    },
    orange: {
      fill: "#ffaa04",
      a50: "rgba(255, 170, 4, 0.5)",
    },
    white: {
      a10: "rgba(255, 255, 255, 0.1)",
      a20: "rgba(255, 255, 255, 0.2)",
      a60: "rgba(255, 255, 255, 0.6)",
      a70: "rgba(255, 255, 255, 0.7)",
      a80: "rgba(255, 255, 255, 0.8)",
      a90: "rgba(255, 255, 255, 0.9)",
    },
  },
  font: {
    size: {
      xs: `1.4${unit}`,
      s: `1.6${unit}`,
      m: `1.8${unit}`,
      l: `2${unit}`,
      xl: `2.4${unit}`,
      xxl: `3${unit}`,
      xxxl: `3.4${unit}`,
      xxxxl: `4${unit}`,
      xxxxxl: `4.6${unit}`,
    },
    weight: {
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
  },
  padding: {
    small: `0.8${unit}`,
    default: `6.4${unit}`,
  },
  transition: {
    time: {
      default: "0.2s",
    },
  },
};
export type Theme = typeof theme;
