import * as Styled from "./Logo.styles";

export const Logo = () => {
  return (
    <Styled.Wrapper>
      <Styled.Logo>L</Styled.Logo>
      <Styled.Text>Selectro TAXFREE</Styled.Text>
    </Styled.Wrapper>
  );
};
