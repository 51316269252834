import styled from "styled-components";

export const CardNumber = styled.p`
  font-size: ${({ theme }) => theme.font.size.l};
  letter-spacing: 0.12rem;
  margin: 0;
`;
export const Content = styled.main`
  display: flex;
  gap: 3rem;
`;
export const Label = styled.label`
  color: ${({ theme }) => theme.color.white.a60};
  font-size: ${({ theme }) => theme.font.size.xs};
`;
export const NumberSymbol = styled.span`
  color: ${({ theme }) => theme.color.white.a60};
  font-size: ${({ theme }) => theme.font.size.xs};
`;
export const Owner = styled.div`
  align-items: center;
  display: grid;
  column-gap: 1.4rem;
  grid-template-columns: auto auto;
`;
export const QRCode = styled.img`
  height: 7.3rem;
  width: 7.3rem;
`;
export const QRCodeWrapper = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.color.white.a10};
  border: 0.1rem solid ${({ theme }) => theme.color.white.a60};
  border-radius: 1.1rem;
  display: flex;
  justify-content: center;
  height: 8.3rem;
  width: 8.3rem;
`;
export const Title = styled.h3`
  font-size: ${({ theme }) => theme.font.size.l};
  font-weight: 600;
  margin: 0;
`;
export const Value = styled.span`
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
`;
export const Wrapper = styled.div`
  background: url("card_bg.png");
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  height: 19.5rem;
  justify-content: space-between;
  margin: 6.6rem auto 4.2rem;
  padding: 1.2rem 2.2rem;
  width: 34.4rem;
`;
