import React, { ReactNode } from "react";

export enum BUTTON_VARIANTS {
  PRIMARY = "primary",
  SECONDARY = "SECONDARY",
}

export interface StyledButtonProps {
  variant?: BUTTON_VARIANTS;
}

export interface ButtonProps extends StyledButtonProps {
  children: ReactNode;
  onClick?: (e: React.FormEvent) => void;
}
