import * as Styled from "./Summary.styles";

export const Summary = () => {
  return (
    <Styled.Wrapper>
      <Styled.Counter>
        <Styled.Label>Uzyskane zwroty</Styled.Label>
        <Styled.Value>414,00 PLN</Styled.Value>
      </Styled.Counter>
      <Styled.Spacer />
      <Styled.Counter>
        <Styled.Label>W trakcie rozliczania</Styled.Label>
        <Styled.Value>414,00 PLN</Styled.Value>
      </Styled.Counter>
    </Styled.Wrapper>
  );
};
