import { Button } from "components/Button";
import { Input } from "components/Input";
import { Logo } from "components/Logo";
import { motion } from "framer-motion";
import { ROUTE } from "routes/routes.types";
import * as Styled from "./Register.styles";

export const Register = () => {
  return (
    <>
      <Styled.LogoContainer>
        <Logo />
      </Styled.LogoContainer>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        exit={{ x: "-100%" }}
        transition={{ bounce: 0 }}
      >
        <Styled.Wrapper>
          <Styled.Title>Utwórz konto</Styled.Title>
          <Styled.Form>
            <Input labelText="Email" />
            <Input labelText="Hasło" type="password" />
            <Input labelText="Powtórz hasło" type="password" />
            <Button>Utwórz konto</Button>
          </Styled.Form>
          <Styled.Actions>
            <span>
              Jesteś już użytkownikiem?{" "}
              <Styled.Link to={ROUTE.LOGIN}>Zaloguj się</Styled.Link>
            </span>
          </Styled.Actions>
        </Styled.Wrapper>
      </motion.div>
    </>
  );
};
