import styled from "styled-components";
import { StyledChipProps } from "./Chip.types";

export const Wrapper = styled.span<StyledChipProps>`
  background-color: ${({ theme, variant }) => theme.color[variant].a50};
  border: 0.1rem solid ${({ theme, variant }) => theme.color[variant].fill};
  border-radius: 9rem;
  font-size: ${({ theme }) => theme.font.size.xs};
  padding: 0.5rem 0.6rem;
  width: fit-content;
`;
