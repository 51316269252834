import { TitleProps } from "./Title.types";
import { Helmet } from "react-helmet-async";

export const Title = ({ title }: TitleProps) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};
