import { createGlobalStyle } from "styled-components";

const fontDefaults = `
  font-family: 'Hind Vadodara', sans-serif;
    font-size: 1.6rem;
`;

export const GlobalStyle = createGlobalStyle`/* Firefox */
  * {
    scrollbar-width: none;
    scrollbar-color: #8f54a0 #ffffff;
    box-sizing: border-box;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    max-height: 4px;;
    width: 4px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background: linear-gradient(179.93deg, rgba(151, 71, 255, 0.5) 0.06%, rgba(217, 217, 217, 0) 113.47%);

    /* background-color: rgba(151, 71, 255, 0.5); */
    border-radius: 4px;
    border: 0px none #ffffff;
  }
  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-font-variant-ligatures: none;
    font-size: 62.5%;
    font-smoothing: antialiased;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    min-height: 100%;
  }

  body {
    background-color: #000e23;
    background-position: fixed;
    background:
      linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 90%, rgba(0,14,35,1) 100%),
      url('bg.svg'),
      radial-gradient(449.73% 119.34% at 4% 0.92%, rgba(131, 173, 255, 0.25) 0%, rgba(19, 25, 58, 0) 100%),
      #000e23;
    background-repeat: no-repeat;
    background-size: cover;
    color: ${({ theme }) => theme.color.white.a90};
    min-height: 100%;
  }

  body, button, input {
    ${fontDefaults}
  }

  a {
    color: ${({ theme }) => theme.color.white.a90};
    text-decoration: none;
  }
`;

export const LoginStyle = createGlobalStyle`
  * {
    scrollbar-width: none;
    scrollbar-color: #8f54a0 #ffffff;
    box-sizing: border-box;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    max-height: 4px;
    width: 4px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background: linear-gradient(
      179.93deg,
      rgba(151, 71, 255, 0.5) 0.06%,
      rgba(217, 217, 217, 0) 113.47%
    );

    /* background-color: rgba(151, 71, 255, 0.5); */
    border-radius: 4px;
    border: 0px none #ffffff;
  }
  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-font-variant-ligatures: none;
    font-size: 62.5%;
    font-smoothing: antialiased;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    height: 100%;
  }

  body {
    background-color: #000e23;
    background-position: fixed;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 90%,
        rgba(0, 14, 35, 1) 100%
      ),
      url("bg-login.svg"),
      radial-gradient(
        449.73% 119.34% at 4% 0.92%,
        rgba(131, 173, 255, 0.25) 0%,
        rgba(19, 25, 58, 0) 100%
      ),
      #000e23;
    background-repeat: no-repeat;
    background-size: cover;
    color: ${({ theme }) => theme.color.white.a90};
    min-height: 100%;
    position: relative;
    @media (max-width: 40rem) {
      max-width: 100vw;
    }
  }

  body,
  button,
  input {
    ${fontDefaults}
  }

  a {
    color: ${({ theme }) => theme.color.white.a90};
    text-decoration: none;
  }
`;
