import { Logo } from "components/Logo";
import { Menu } from "components/Menu";
import * as Styled from "./Nav.styles";

export const Nav = () => {
  return (
    <Styled.Nav>
      <Logo />
      <Menu />
    </Styled.Nav>
  );
};
