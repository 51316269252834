import { Button } from "components/Button";
import { BUTTON_VARIANTS } from "components/Button/Button.types";
import { Input } from "components/Input";
import { Modal } from "components/Modal";
import { FormEvent } from "react";
import { AddNewDocumentModalProps } from "./AddNewDocument.types";
import * as Styled from "./AddNewDocumentModal.styles";

export const AddNewDocumentModal = ({
  show,
  setShow,
}: AddNewDocumentModalProps) => {
  const handleAction = (e: FormEvent) => {
    e.preventDefault();
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <Modal show={show} setShow={setShow}>
        <Styled.Content>
          <Styled.Title>Dodaj nowy dokument TAX FREE</Styled.Title>
          <Styled.Form>
            <Input
              type="text"
              labelText="Numer UNS"
              additionalText="Gdzie znaidę numer UNS?"
            />
            <Input
              type="number"
              labelText="Numer paragonu"
              additionalText="Gdzie znaidę numer paragonu?"
            />
            <Styled.Actions>
              <Button onClick={handleAction}>Dodaj dokument</Button>
              <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={handleClose}>
                Anuluj
              </Button>
            </Styled.Actions>
          </Styled.Form>
        </Styled.Content>
      </Modal>
    </>
  );
};
