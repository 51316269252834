import styled, { css } from "styled-components";

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 3rem;
`;
export const Content = styled.div`
  background-color: #031127;
  background: radial-gradient(
      308.54% 71.68% at 4% 0.92%,
      rgba(131, 173, 255, 0.15) 0%,
      rgba(19, 25, 58, 0) 100%
    ),
    #031127;
  height: 100%;
  padding: 8.7rem 11.1rem;
  text-align: center;
  width: 64.4rem;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;
export const Title = styled.h2(({ theme }) => {
  return css`
    font-size: ${theme.font.size.xxxxxl};
    font-weight: ${theme.font.weight.semibold};
  `;
});
