import { MenuItem } from "components/MenuItem";
import * as Styled from "./Menu.styles";
import { ReactComponent as DashboardIcon } from "assets/icon/dashboard.svg";
import { ReactComponent as DocumentsIcon } from "assets/icon/documents.svg";
import { ReactComponent as RefundsIcon } from "assets/icon/refunds.svg";
import { ReactComponent as SettingsIcon } from "assets/icon/settings.svg";
import { useLocation } from "react-router-dom";
import { ROUTE } from "routes/routes.types";

export const Menu = () => {
  const { pathname } = useLocation();

  return (
    <Styled.Menu>
      <Styled.Link to={ROUTE.HOME} active={ROUTE.HOME === pathname}>
        <MenuItem icon={<DashboardIcon />}>Pulpit</MenuItem>
      </Styled.Link>
      <Styled.Link to={ROUTE.DOCUMENTS} active={ROUTE.DOCUMENTS === pathname}>
        <MenuItem icon={<DocumentsIcon />}>Dokumenty</MenuItem>
      </Styled.Link>
      <Styled.Link to={ROUTE.REFUNDS} active={ROUTE.REFUNDS === pathname}>
        <MenuItem icon={<RefundsIcon />}>Zwroty</MenuItem>
      </Styled.Link>
      <Styled.Link to={ROUTE.SETTINGS} active={ROUTE.SETTINGS === pathname}>
        <MenuItem icon={<SettingsIcon />}>Ustawienia</MenuItem>
      </Styled.Link>
    </Styled.Menu>
  );
};
