import { MenuItemProps } from "./MenuItem.types";
import * as Styled from "./MenuItem.styles";

export const MenuItem = ({ children, icon }: MenuItemProps) => {
  return (
    <Styled.MenuItem>
      {icon}
      {children}
    </Styled.MenuItem>
  );
};
