import styled from "styled-components";

export const Counter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.padding.small};
  justify-content: center;
  min-height: 20rem;
`;
export const Label = styled.h3`
  color: ${({ theme }) => theme.color.white.a60};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  margin: 0;
`;
export const Spacer = styled.div`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) -0.65%,
    rgba(0, 14, 35, 0.2) 105.51%
  );
  width: 0.1rem;
`;
export const Value = styled.p`
  font-size: ${({ theme }) => theme.font.size.xxxl};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0;
`;
export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.1rem 1fr;
`;
