import { Dashboard } from "page/Dashboard";
import { Documents } from "page/Documents";
import { Login } from "page/Login";
import { Refunds } from "page/Refunds";
import { Register } from "page/Register";
import { Settings } from "page/Settings";
import { ProtectedRoute } from "ProtectedRoute";
import { RouteObject } from "react-router-dom";
import { ROUTE } from "./routes.types";

export const routes: RouteObject[] = [
  {
    path: ROUTE.DASHBOARD,
    element: <ProtectedRoute component={Dashboard} />,
  },
  {
    path: ROUTE.DOCUMENTS,
    element: <ProtectedRoute component={Documents} />,
  },
  {
    path: ROUTE.HOME,
    element: <ProtectedRoute component={Dashboard} />,
  },
  {
    path: ROUTE.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTE.REFUNDS,
    element: <ProtectedRoute component={Refunds} />,
  },
  {
    path: ROUTE.REGISTER,
    element: <Register />,
  },
  {
    path: ROUTE.SETTINGS,
    element: <ProtectedRoute component={Settings} />,
  },
];
