import { Button } from "components/Button";
import { Input } from "components/Input";
import { Logo } from "components/Logo";
import { motion } from "framer-motion";
import { ChangeEvent, FormEvent, useState } from "react";
import { Navigate } from "react-router-dom";
import { ROUTE } from "routes/routes.types";
import * as Styled from "./Login.styles";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logged, setLogged] = useState(false);
  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const handleLogin = (e: FormEvent<Element>) => {
    e.preventDefault();
    if (email === "test@smartive.app" && password === "smartive") {
      localStorage.setItem("logged", "yaa");
      setLogged(true);
    }
  };
  return (
    <>
      {logged && <Navigate to={ROUTE.HOME} />}
      <Styled.LogoContainer>
        <Logo />
      </Styled.LogoContainer>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        exit={{ x: "-100%" }}
        transition={{ bounce: 0 }}
      >
        <Styled.Wrapper>
          <Styled.Title>Zaloguj się</Styled.Title>
          <Styled.Form onSubmit={handleLogin}>
            <Input
              labelText="Email"
              type="email"
              onChange={handleChangeEmail}
            />
            <Input
              labelText="Hasło"
              type="password"
              onChange={handleChangePassword}
            />
            <Button onClick={handleLogin}>Zaloguj się</Button>
          </Styled.Form>
          <Styled.Actions>
            <Styled.Link to="">Nie pamiętasz hasła?</Styled.Link>
            <span>
              Nie masz konta?{" "}
              <Styled.Link to={ROUTE.REGISTER}>Utwórz konto</Styled.Link>
            </span>
            <span>
              To witryna jest chroniona przez reCAPTCHA i obowiązuje{" "}
              <Styled.Link to="">Polityka prywatności</Styled.Link> Google oraz{" "}
              <Styled.Link to="">Regulamin</Styled.Link> usługi.
            </span>
          </Styled.Actions>
        </Styled.Wrapper>
      </motion.div>
    </>
  );
};
