import styled from "styled-components";

export const Logo = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.black.fill};
  border-radius: 50%;
  display: flex;
  font-size: 2.4rem;
  font-weight: 600;
  height: 3.5rem;
  justify-content: center;
  width: 3.5rem;
`;
export const Text = styled.span``;
export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
  gap: 1rem;
`;
