import { Title } from "components/Title";

export const Refunds = () => {
  return (
    <>
      <Title title="Zwroty" />
      Refunds
    </>
  );
};
