import styled from "styled-components";
import { CellProps } from "./DocumentList.types";

export const Cell = styled.td<CellProps>`
  padding: 1rem 0;
  text-align: ${({ center }) => center && "center"};
`;
export const Row = styled.tr`
  td:nth-of-type(2) {
    font-size: ${({ theme }) => theme.font.size.xs};
  }
  td:nth-of-type(3) {
    font-size: ${({ theme }) => theme.font.size.xs};
  }
  td:nth-of-type(4) {
    font-size: ${({ theme }) => theme.font.size.m};
    font-weight: ${({ theme }) => theme.font.weight.semibold};
  }
  td:nth-of-type(6) {
    font-size: ${({ theme }) => theme.font.size.xs};
  }
`;
export const Spacer = styled.td`
  background: linear-gradient(
    270.01deg,
    rgba(255, 255, 255, 0.2) -0.65%,
    rgba(0, 14, 35, 0.2) 105.51%
  );

  height: 0.1rem;
  padding: 0;
`;
export const Table = styled.table`
  margin-left: ${({ theme }) => theme.padding.default};
  margin-top: 1.6rem;
  width: calc(100% - 6.4rem);
`;
export const Wrapper = styled.div`
  max-height: 54rem;
  overflow: auto;
`;
