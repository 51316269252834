import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { StyledLinkProps } from "./Menu.types";

export const Link = styled(RouterLink)<StyledLinkProps>`
  align-items: center;
  background: ${({ active, theme }) => active && theme.color.black.a60};
  display: flex;
  transition: all 0.3s ease-in-out;
`;
export const Menu = styled.ul`
  align-self: stretch;
  bottom: 0;
  display: flex;
  gap: 3.1rem;
  list-style: none;
  margin: 0;
  top: 0;
`;
