import { ButtonProps, BUTTON_VARIANTS } from "./Button.types";
import * as Styled from "./Button.styles";

export const Button = ({
  children,
  onClick,
  variant = BUTTON_VARIANTS.PRIMARY,
}: ButtonProps) => {
  return (
    <Styled.Button onClick={onClick} variant={variant}>
      {children}
    </Styled.Button>
  );
};
